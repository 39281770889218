import React, { useState } from 'react';
import { Chart } from 'react-google-charts';
import { useStreet } from './components/StreetSelector';
import { useMultipleStreets } from './components/MultiStreetSelector';

const processTemperatureData = (data, selectedStreets) => {
  // Create headers dynamically based on the selected streets
  const headers = ["Date", 
    ...selectedStreets.flatMap(street => [
      `${street}`
    ])
  ];

  // Group data by date for all selected streets
  const groupedData = data.reduce((acc, entry) => {
    const date = new Date(entry.DATE).toDateString();
    if (!acc[date]) {
      acc[date] = { date: new Date(entry.DATE) };
    }
    selectedStreets.forEach(street => {
      if (!acc[date][street]) {
        acc[date][street] = {
          temperature: entry.Street === street ? entry["Temperature"] : null
        };
      }
    });
    return acc;
  }, {});

  // Create rows with temperature and pH data for each street per date
  const rows = [];
  Object.keys(groupedData).forEach(date => {
    const row = [new Date(date)];
    selectedStreets.forEach(street => {
      row.push(groupedData[date][street]?.temperature || null); // Temperature
    });
    rows.push(row);
  });

  return [headers, ...rows];
};


const processPhData = (data, selectedStreets) => {
  // Create headers dynamically based on the selected streets
  const headers = ["Date", ...selectedStreets.map(street => `${street} - pH`)];
  
  // Group data by date for all selected streets
  const groupedData = data.reduce((acc, entry) => {
    const date = new Date(entry.DATE).toDateString();
    if (!acc[date]) {
      acc[date] = { date: new Date(entry.DATE) };
    }
    selectedStreets.forEach(street => {
      if (!acc[date][street]) {
        acc[date][street] = entry.Street === street ? entry.pH : null;
      }
    });
    return acc;
  }, {});

  // Create rows with pH data for each street per date
  const rows = [];
  Object.keys(groupedData).forEach(date => {
    const row = [new Date(date)];
    selectedStreets.forEach(street => {
      row.push(groupedData[date][street] || null); // Fill with null if no data
    });
    rows.push(row);
  });

  return [headers, ...rows];
};


const processChlorineData = (data) => {
  const headers = ["Date", "Total Chlorine Residual", "Monochloramine"];
  const rows = data.map((entry) => [
    new Date(entry.DATE),
    entry["Total Chlorine Residual"],
    entry.Monochloramine,
  ]);
  return [headers, ...rows];
};
// Updated processChlorineData function
const processChlorineDataMulti = (data, selectedStreets) => {
  // Create headers with a single label for each street's data
  const headers = ["Date", ...selectedStreets.flatMap(street => [`${street}`])];

  // Group data by date for all selected streets
  const groupedData = data.reduce((acc, entry) => {
    const date = new Date(entry.DATE).toDateString();
    if (!acc[date]) {
      acc[date] = { date: new Date(entry.DATE) };
    }
    acc[date][entry.Street] = {
      "Total Chlorine Residual": entry["Total Chlorine Residual"],
      // Monochloramine: entry.Monochloramine
    };
    return acc;
  }, {});

  // Create rows with data for each street per date
  const rows = [];
  Object.keys(groupedData).forEach((date) => {
    const row = [new Date(date)];
    selectedStreets.forEach((street) => {
      const streetData = groupedData[date][street] || {};
      row.push(streetData["Total Chlorine Residual"] || null); // Fill with null if no data
      // row.push(streetData.Monochloramine || null);
    });
    rows.push(row);
  });

  return [headers, ...rows];
};

const processFreeAmmoniaData = (data, selectedStreets) => {
  // Create headers dynamically based on the selected streets
  const headers = ["Date", 
    ...selectedStreets.map(street => `${street} - Free Ammonia`)
  ];

  // Group data by date for all selected streets
  const groupedData = data.reduce((acc, entry) => {
    const date = new Date(entry.DATE).toDateString();
    if (!acc[date]) {
      acc[date] = { date: new Date(entry.DATE) };
    }
    selectedStreets.forEach(street => {
      if (!acc[date][street]) {
        acc[date][street] = entry.Street === street ? entry["Free Ammonia"] : null;
      }
    });
    return acc;
  }, {});

  // Create rows with Free Ammonia data for each street per date
  const rows = [];
  Object.keys(groupedData).forEach(date => {
    const row = [new Date(date)];
    selectedStreets.forEach(street => {
      row.push(groupedData[date][street] || null); // Free Ammonia
    });
    rows.push(row);
  });

  return [headers, ...rows];
};


const processNitritesData = (data, selectedStreets) => {
  // Create headers dynamically based on the selected streets
  const headers = ["Date", 
    ...selectedStreets.map(street => `${street} - Nitrites`)
  ];

  // Group data by date for all selected streets
  const groupedData = data.reduce((acc, entry) => {
    const date = new Date(entry.DATE).toDateString();
    if (!acc[date]) {
      acc[date] = { date: new Date(entry.DATE) };
    }
    selectedStreets.forEach(street => {
      if (!acc[date][street]) {
        acc[date][street] = entry.Street === street ? entry["Nitrites"] : null;
      }
    });
    return acc;
  }, {});

  // Create rows with Nitrites data for each street per date
  const rows = [];
  Object.keys(groupedData).forEach(date => {
    const row = [new Date(date)];
    selectedStreets.forEach(street => {
      row.push(groupedData[date][street] || null); // Nitrites
    });
    rows.push(row);
  });

  return [headers, ...rows];
};


const processTotalAlkalinityAs_CaCO3 = (data, selectedStreets) => {
  // Create headers dynamically based on the selected streets
  const headers = ["Date", 
    ...selectedStreets.map(street => `${street} - Total Alkalinity (as CaCO3)`)
  ];

  // Group data by date for all selected streets
  const groupedData = data.reduce((acc, entry) => {
    const date = new Date(entry.DATE).toDateString();
    if (!acc[date]) {
      acc[date] = { date: new Date(entry.DATE) };
    }
    selectedStreets.forEach(street => {
      if (!acc[date][street]) {
        acc[date][street] = entry.Street === street ? entry["Total Alkalinity (as CaCO3)"] : null;
      }
    });
    return acc;
  }, {});

  // Create rows with Total Alkalinity data for each street per date
  const rows = [];
  Object.keys(groupedData).forEach(date => {
    const row = [new Date(date)];
    selectedStreets.forEach(street => {
      row.push(groupedData[date][street] || null); // Total Alkalinity (as CaCO3)
    });
    rows.push(row);
  });

  return [headers, ...rows];
};


const options = {
  // title: 'Chlorine Quality Over Time',
  curveType: 'function',
  interpolateNulls: true,  // This ensures lines are drawn across missing data points
  legend: { position: 'bottom' },
};

const LineChart = ({ data }) => {
  const { selectedStreet } = useStreet();
  const { selectedStreets } = useMultipleStreets();

  const chlorineOptions = {
    title: 'Chlorine Levels',
    hAxis: { title: 'Date' },
    vAxis: { title: 'Chlorine Levels (milligrams per liter (mg/L))' },
    curveType: "function",
    interpolateNulls: true,  // Connect lines across missing data points
    legend: { position: 'top', alignment: 'right' }, // Customize legend position
    series: selectedStreets.flatMap((street, index) => ({
      [`${index * 2 + 1}`]: { label: `${street} - ` },
      [`${index * 2 + 1}`]: { label: `${street} - ` },
    })).reduce((acc, cur) => ({ ...acc, ...cur }), {}), // Flatten the series configuration
  };
  //const filteredData = data.filter((entry) => entry.Street === selectedStreet);
// Filter data for multiple selected streets
const filteredData = data.filter((entry) =>
  selectedStreets.includes(entry.Street)
);
  const tempAndPHChartData = processTemperatureData(filteredData, selectedStreets);
  const chlorineChartData = processChlorineDataMulti(filteredData, selectedStreets);
  const phChartData = processPhData(filteredData, selectedStreets);
  const freeAmmoniaData = processFreeAmmoniaData(filteredData, selectedStreets);
  const nitritesData = processNitritesData(filteredData, selectedStreets);
  const totalAlkalinityAs_CaCO3Data = processTotalAlkalinityAs_CaCO3(filteredData, selectedStreets);


  return (
    <div>
      <div>
        {selectedStreets && selectedStreets.length > 0 ? (
          1==1
        ) : (
          <p>No streets selected in multi-street selector.</p>
        )}
      </div>
      {selectedStreets  && selectedStreets.length > 0 ? (
        <div>
          <Chart
            chartType="LineChart"
            width="100%"
            height="800px"
            data={chlorineChartData}
            options={chlorineOptions}
          />
        <Chart
          chartType="LineChart"
          width="100%"
          height="800px"
          data={phChartData}
          options={options}
        />
        <Chart
          chartType="LineChart"
          width="100%"
          height="800px"
          data={tempAndPHChartData}
          options={options}
        />
        <Chart
          chartType="LineChart"
          width="100%"
          height="800px"
          data={freeAmmoniaData}
          options={options}
        />
        <Chart
          chartType="LineChart"
          width="100%"
          height="800px"
          data={nitritesData}
          options={options}
        />
        <Chart
          chartType="LineChart"
          width="100%"
          height="800px"
          data={totalAlkalinityAs_CaCO3Data}
          options={options}
        />
        </div>
      ) : (
        <p></p>
      )}
    </div>
  );
};

export default LineChart;
